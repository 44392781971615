'use client'
import { usePathname } from '@/navigation'
import { useEffect, useState } from 'react'

export default function CCP_CookieConsent() {
  const pathname = usePathname() || '/'
  const isHomepage = pathname === '/'
  const [isGooglePageSpeed, setIsGooglePageSpeed] = useState(false)
  const [firstPathname, setFirstPathname] = useState(pathname)

  // Check if user agent is a bot
  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      setIsGooglePageSpeed(
        /bot|moto g power/.test(navigator.userAgent.toLowerCase() || '')
      )
    }
    setFirstPathname(pathname)
  }, [])

  // Handle homepage scroll behavior
  useEffect(() => {
    if (!isHomepage) return

    const handleScroll = () => {
      const cookieContainer = document.querySelector('.cwc-float-container')
      const widgetIcon = document.querySelector('.cwc-widget-icon')

      if (!cookieContainer && !widgetIcon) return

      const scrollPosition = window.scrollY
      const windowHeight = window.innerHeight
      const documentHeight = document.documentElement.scrollHeight
      const scrolledToBottom =
        documentHeight - (scrollPosition + windowHeight) < 100

      if (cookieContainer) {
        cookieContainer.style.transition = 'transform 0.3s ease'
        cookieContainer.style.transform =
          scrollPosition > 100 && !scrolledToBottom
            ? 'translateY(300px)'
            : 'translateY(0)'
      }

      if (widgetIcon) {
        widgetIcon.style.transition = 'transform 0.3s ease'
        widgetIcon.style.transform =
          scrollPosition > 100 && !scrolledToBottom
            ? 'translateY(80px)'
            : 'translateY(0)'
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isHomepage])

  // Toggle cookie consent visibility
  useEffect(() => {
    const cookieContainer = document.querySelector('.cwc-sdk-container')
    if (cookieContainer) {
      cookieContainer.style.display = isHomepage ? 'block' : 'none'
    }
  }, [pathname, isHomepage])

  // Don't render for bots
  if (isGooglePageSpeed || firstPathname !== '/') return null

  return (
    <>
      <head>
        <script src="https://cookiecdn.com/cwc.js" defer />
        <script
          id="cookieWow"
          src="https://cookiecdn.com/configs/Gp9k8iW5zF5o21y1M3krNrB3"
          data-cwcid="Gp9k8iW5zF5o21y1M3krNrB3"
          key={`cookie-wow-${pathname}`}
          defer
        />
      </head>
    </>
  )
}
