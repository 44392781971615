module.exports = {
  primary: '#002D73',
  secondary: '#1F1F1F',
  facebook: '#1877F2',
  'facebook-messenger': '#006AFF',
  fb: '#3b5998',
  twitter: '#14171A',
  youtube: 'rgb(196, 48, 43)',
  line: 'rgb(29, 185, 84)',
  instagram: 'rgb(240, 0, 117)',
  'bht-primary': '#ce0f69',
  'bgh-fpd-blue-light': '#E7EDFF99',
  'bgh-fpd-blue': '#0147A3',
  'bgh-fpd-blue-dark': '#0147A3',
  'bgh-fpd-green': '#007E50',
  'bgh-fpd-gray': '#F2F2F2',
  'bgh-fpd-blue-bright': '#3987F4',
  'bgh-field-placeholder': '#002D7380',
  'bgh-field-placeholder-solid': '#002D73',
  'bgh-field-label': '#002D73',
  'bgh-field-value': '#0147a3',
  'bgh-white': '#fbfbfc',
  'bgh-black-alpha': '#0000001A',
  'bgh-dark': '#363636',
  'bgh-skeleton': 'rgba(0,0,0,0.11)',
  'bgh-translucent': 'hsla(0,0%,100%,.35)',
  'bgh-gray-primary': '#768692',
  'bgh-gray-dark': '#556978',
  'bgh-ow-gray': '#8e8e8e',
  'bgh-gray-primary-hover': '#5e6c77',
  'bgh-gray': '#627380',
  'bgh-gray-light': '#f5f5f5',
  'bgh-gray-line': '#e5e7eb',
  'bgh-blue-alpha': '#e7edff',
  'bgh-blue-fade': '#F5F8FF',
  'bgh-blue': '#0147a3',
  'bgh-red': '#b21e28',
  'bgh-blue-light': '#8baaff',
  'bgh-blue-border': '#c3d0f7',
  'bgh-blue-gloomy': '#6F81D4',
  'bgh-blue-bright': '#236dce',
  'bgh-blue-vivid': '#1b17de',
  'vip-primary': '#131c2c',
  'vip-secondary': '#DCE5F5',
  'vip-text': '#002D73',
  'vip-gray': '#717171',
  'vip-stroke': '#E3E3EA',
  'default-line': '#0000003b',
  'heart-positive': '#17cf73',
  'heart-negative': '#ff4d4d',
  'heart-warning': '#f5b83d',
}
